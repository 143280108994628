@import '@legalzoom/ui/build/index.css';

@config "../tailwind.nav-modal.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Necessary to override tailwind's default grey border */
  /* See: https://github.com/tailwindlabs/tailwindcss/discussions/2994 */
  *,
  ::before,
  ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: currentColor;
  }

  html {
    font-size: 16px !important;
  }
}

@layer utilities {
  lz-navbar {
    @apply nav-block nav-min-h-[3.5rem] md:nav-min-h-[5rem];
  }

  .nav-btn-action {
    @apply !nav-border-brand !nav-bg-brand !nav-text-center !nav-text-white;
  }
  .nav-btn-action:hover {
    @apply !nav-border-brand-600 !nav-bg-brand-600;
  }
  .nav-btn-action:active {
    @apply !nav-border-brand-700 !nav-bg-brand-700;
  }

  .nav-btn-outline {
    @apply !nav-rounded !nav-border-black !nav-bg-white !nav-text-center !nav-text-black;
  }
  .nav-btn-outline:active {
    @apply !nav-border-default-response !nav-bg-default-response !nav-text-white;
  }
  .nav-btn-outline:hover {
    @apply !nav-border-muted !nav-bg-muted !nav-text-white;
  }
  .nav-btn-outline:focus {
    @apply !nav-border-muted !nav-bg-muted !nav-text-white;
  }
  .nav-btn-outline:visited {
    @apply !nav-border-black !nav-bg-white !nav-text-black;
  }

  .nav-btn-mobile {
    @apply !nav-rounded-full !nav-border-2 !nav-px-9 !nav-py-3 !nav-text-base !nav-font-semibold !nav-leading-5 -nav-tracking-[.2px] !nav-no-underline;
  }
}
